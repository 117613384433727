import { Button, Box } from '@mui/material';
import { useRef } from 'react';
import { v4 as uuidv4 } from 'uuid';

import { useFileError } from '../../context/FileErrorContext';
import FileInput from '../../common/components/Upload/FileInput';
import StyledCard from '../styledCard/StyledCard';
import DocDropzone from '../../common/components/Upload/DocDropzone';
import { ReactComponent as Add } from '../../assets/img/add.svg';
import { isFileSizeValid, isValidExtension } from '../../common/utils/filesUtil';
import { useFiles } from '../../context/FilesContext';

const DropDocumentsCard = () => {
    const fileInput = useRef();
    const { setShowMessage, setErrorMessage } = useFileError();
    const { setFiles, allMimeTypes, allExtensions } = useFiles();

    const onChange = (addedFiles) => {
        let fileTooLarge = false;
        let invalidExtension = false;
        var filteredFiles = addedFiles.filter(function (file) {
            if (!isFileSizeValid(file)) {
                fileTooLarge = true;
                setErrorMessage('One or more files were larger than 25MB and could not be added.');
            } else if (!isValidExtension(allExtensions, file)) {
                invalidExtension = true;
                setErrorMessage('One or more files were blocked file types and could not be added.');
            } else {
                file.id = uuidv4();
                return file;
            }
        });
        setShowMessage(fileTooLarge || invalidExtension);
        setFiles((prevFiles) => [...prevFiles, ...filteredFiles]);
        resetInput();
    };

    function resetInput() {
        fileInput.current.value = '';
    }

    return (
        <StyledCard
            svg={<Add style={{ width: 'auto', height: '30%' }} />}
            title='Add Documents'
            subtitle='Please add your supporting documents below'
            content={
                <Box sx={{ height: '60%', width: '100%', p: '5%' }}>
                    <DocDropzone onDrop={onChange} />
                    <FileInput
                        ref={fileInput}
                        onChange={onChange}
                        accept={allMimeTypes}
                    />
                </Box>
            }
            actions={
                <Button size="large" onClick={() => fileInput.current.click()} variant='contained' style={{ textTransform: 'none' }}>
                    Browse For Documents
                </Button>
            }
        >
        </StyledCard>
    )
}

export default DropDocumentsCard;