import { useEffect } from "react";

import StyledCard from "../styledCard/StyledCard";
import { ReactComponent as CabinSvg } from '../../assets/img/cabin.svg';
import { HomeTitle, HomeCardSubtitle, HomeHeader } from "./HomeText";
import { useProgress } from "../../context/ProgressContext";

const HomeCard = () => {
    const { setPageTitle } = useProgress();
    useEffect(() => {
      setPageTitle(<HomeHeader />);
    },[])

    return (
        <StyledCard
            svg={<CabinSvg style={{ width: 'auto', height: '30%' }} />}
            title={<HomeTitle />}
            subtitle={<HomeCardSubtitle />}>
        </StyledCard >
    )
}

export default HomeCard;