import { useState, createContext, useContext } from 'react';

const MobileContext = createContext();

export function useMobile() {
  return useContext(MobileContext);
}

export function MobileProvider({ children }) {
  window.addEventListener('resize', updateWindowDimensions);
  const [mobile, setMobile] = useState(isMobile());

  function isMobile() {
    return window.innerWidth < 900 || 
    (window.innerHeight / window.innerWidth) > 0.7;
  }

  function updateWindowDimensions() {
    if (isMobile()) {
      setMobile(true);
    }
    else {
      setMobile(false);
    }
  }

  return (
    <MobileContext.Provider value={{ mobile }}>
      {children}
    </MobileContext.Provider>
  );
}
