import { Box } from '@mui/material';
import { useEffect } from 'react';

import VerificationFailedDesc from './VerificationFailedDesc';
import { VerificationFailedHeader } from './VerificationFailedText';
import { useProgress } from '../../../context/ProgressContext';

const VerificationFailedContainer = (props) => {
  const { errorTitle, errorMessage } = props;
  const { setPageTitle } = useProgress();

  useEffect(() => {
    setPageTitle(<VerificationFailedHeader />);
  },[])
  
  return (
    <>
      <Box sx={{pt: '10vh'}}>
        <VerificationFailedDesc errorTitle={errorTitle} errorMessage={errorMessage} />
      </Box>
    </>
  );
};

export default VerificationFailedContainer;
