import Dropzone from "react-dropzone";
import { UploadFile } from "@mui/icons-material";
import { Box, Typography } from "@mui/material";

import { useTheme } from '../../../context/ThemeContext';
import { useEffect, useState } from "react";

const DocDropzone = ({ onDrop }) => {
    const { theme } = useTheme();
    const [color, setColor] = useState('');
    const [formattedColor, setFormattedColor] = useState();

    useEffect(() => {
        setColor(theme === 'light' ? '#08568b80' : '#90caf9');
    }, [theme])

    useEffect(() => {
        setFormattedColor(color.replace('#', ''));
    }, [color])

    const style = {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%',
        backgroundImage: `url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%23${formattedColor}' stroke-width='8' stroke-dasharray='17' stroke-dashoffset='34' stroke-linecap='square'/%3e%3c/svg%3e")`,
    };

    return (
        <Dropzone onDrop={onDrop}>
            {({ getRootProps, getInputProps }) => (
                <Box {...getRootProps({ style })}>
                    <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%', alignItems: 'center', justifyContent: 'center' }}>
                        <UploadFile sx={{ width: 'auto', height: '30%', color: { color } }} />
                        <Typography sx={{pt: 1}} fontSize={20} color={ color }>
                            Drag and Drop
                        </Typography>
                        <input {...getInputProps()} />
                    </Box>
                </Box>
            )}
        </Dropzone>
    )
};

export default DocDropzone;