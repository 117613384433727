import { Card, CardActions, CardContent, Typography } from "@mui/material";
import { useState, useEffect } from "react";

import { SectionTitle } from '../../common/components/Form';

const StyledCard = ({ svg, title, subtitle, content, actions }) => {
    const [height, setHeight] = useState();
    useEffect(() => {
        if (actions === undefined) {
            setHeight('100%');
        }
        else {
            setHeight('90%');
        }
    }, [actions])

    return (
        <Card sx={{ aspectRatio: '3/4', height: '100%' }}>
            <CardContent sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', height: height, p: '7%' }}>
                {svg}
                <SectionTitle>
                    {title}
                </SectionTitle>
                <Typography sx={{ textAlign: 'left', width: 320 }}>
                    {subtitle}
                </Typography>
                {content}
            </CardContent>
            {actions &&
                <CardActions sx={{ height: '10%', display: 'flex', justifyContent: 'flex-end', alignItems: 'flex-end', pr: 3, pb: 3 }}>
                    {actions}
                </CardActions>
            }
        </Card>
    )
}

export default StyledCard;