import { Outlet } from 'react-router-dom';
import { styled } from '@mui/system';

import Header from '../components/Header/Header';

const MainLayout = () => {

  const Main = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    paddingTop: '4rem',
    maxHeight: '100vh',
    maxWidth: '100vw',
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
  });

  return (
    <>
      <Header />
      <Main>
        <Outlet />
      </Main>
    </>
  );
};

export default MainLayout;
