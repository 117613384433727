import { Box } from '@mui/material';
import { useEffect } from 'react';

import FileList from '../../common/components/FileList';
import AddDocumentsRow from '../../common/components/Upload/AddDocumentsRow';
import { useFiles } from '../../context/FilesContext';
import { useProgress } from '../../context/ProgressContext';
import SendButton from '../../common/components/SendButton';
import CancelButton from '../../common/components/CancelButton';
import MobileContainer from '../../common/components/MobileContainer';
import { ReactComponent as InfoSvg } from '../../assets/img/infoImg.svg';
import { AttachTitle, AttachSubtitle, AttachHeader } from './AttachText';

const AttachContainer = () => {
  const { files, setFiles } = useFiles();
  const { setPageTitle } = useProgress();

  useEffect(() => {
    setPageTitle(<AttachHeader />);
  },[])

  return (
    <MobileContainer
      svg={<InfoSvg style={{ width: 'auto', height: '20%', maxHeight: 500 }} />}
      title={<AttachTitle />}
      subtitle={<AttachSubtitle />}
      content={
        <Box sx={{ height: '40%', width: 320 }}>
          <Box sx={{ height: '100%', width: '100%', overflow: 'auto' }}>
            <FileList files={files} setFiles={setFiles} />
          </Box>
          <AddDocumentsRow />
        </Box>
      }
      actions={
        <Box sx={{display: 'flex', width: '100%', justifyContent: 'center'}}>
          <Box sx={{ width: 330 }}>
            <CancelButton style={{ marginRight:15, width: 150 }} />
            <SendButton style={{ marginLeft:15, width: 150 }} />
          </Box>
        </Box>
      }
    >
    </MobileContainer>
  );
};

export default AttachContainer;
