import { Box, Typography } from "@mui/material";
import { useEffect, useState } from "react";

import { SectionTitle } from "./Form";

function MobileContainer({ svg, title, subtitle, content, actions }) {
    const [height, setHeight] = useState();
    useEffect(() => {
        if (actions === undefined) {
            setHeight('95%');
        }
        else {
            setHeight('90%');
        }
    }, [actions])

    return (
        <Box sx={{ aspectRatio: '3/4', height: '100%', minHeight: 600, minWidth: 350 }}>
            <Box sx={{ display: 'flex', pt:'5%', flexDirection: 'column', alignItems: 'center', height: height }}>
                {svg}
                <SectionTitle>
                    {title}
                </SectionTitle>
                <Typography sx={{ textAlign: 'left', width: 320, pt: 1 }}>
                    {subtitle}
                </Typography>
                {content}
            </Box>
            {actions &&
                <Box sx={{ height: '10%', display: 'flex', justifyContent: 'flex-end' }}>
                    {actions}
                </Box>
            }
        </Box>
    )
}

export default MobileContainer;