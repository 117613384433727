import { Box } from '@mui/material';
import { useEffect } from 'react';

import ContactUs from '../../../common/components/ContactUs';
import MobileContainer from '../../../common/components/MobileContainer';
import { useCredentials } from '../../../context/CredentialsContext';
import { useProgress } from '../../../context/ProgressContext';
import TryAgainButton from '../../../common/components/TryAgainButton';
import { ReactComponent as ProblemSvg } from '../../../assets/img/there was a problem.svg';
import { UploadFailedSubtitle, UploadFailedTitle, UploadFailedHeader } from './UploadFailedText';

export const UploadFailedContainer = () => {
  const { friendlyContactPhone, contactPhone, contactEmail } = useCredentials();
  const { setPageTitle } = useProgress();

  useEffect(() => {
    setPageTitle(<UploadFailedHeader />);
  },[])

  return (
    <MobileContainer
      svg={<ProblemSvg style={{ width: 'auto', height: '30%', maxHeight: 500 }} />}
      title={<UploadFailedTitle />}
      subtitle={<UploadFailedSubtitle />}
      content={
        <Box sx={{ display: 'flex', flexDirection: 'column', height: '50%', justifyContent: 'flex-end', alignItems: 'center' }}>
          <TryAgainButton style={{ width: 200, marginTop: 20, marginBottom: 20 }} />
          <ContactUs friendlyPhoneNumber={friendlyContactPhone} phoneNumber={contactPhone} email={contactEmail} />
        </Box>
      }
    >
    </MobileContainer>
  );
};
