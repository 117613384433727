export const UploadSuccessHeader = () => {
    return (
        'Success'
    )
}

export const UploadSuccessTitle = () => {
    return (
        'Documents Uploaded Successfully'
    )
}

export const UploadSuccessSubtitle = () => {
    return (
        'We received your documents. If you need to upload additional items, click below or feel free to exit the browser.'
    )
}