import { useState, createContext, useContext, useEffect } from 'react';

const FileErrorContext = createContext();

export function useFileError() {
  return useContext(FileErrorContext);
}

export function FileErrorProvider({ children }) {
  const [showMessage, setShowMessage] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    if (!showMessage) {
      setErrorMessage('');
    }
  }, [showMessage])

  return (
    <FileErrorContext.Provider value={{ showMessage, setShowMessage, errorMessage, setErrorMessage }}>
      {children}
    </FileErrorContext.Provider>
  );
}
