import { BlockBlobClient } from '@azure/storage-blob';

import apiClient from './apiClient';

async function getUploadSasUri(folder, fileName) {
  let sasUri = '';
  try {
    await apiClient
      .get('/api/Upload/GetUploadUriAsync', {
        params: {
          folder: folder,
          fileName: fileName,
        },
      })
      .then((res) => {
        sasUri = res.data.uploadUri;
      });
  } catch (error) {
    throw error;
  }
  return sasUri;
}

async function uploadFileToSasUri(sasUri, file, progress) {
  const blockBlobClient = new BlockBlobClient(sasUri);
  try {
    await blockBlobClient.uploadData(file, {
      onProgress: async (ev) => {
        progress(file, ev);
      },
    });
  } catch (error) {
    throw error;
  }
}

async function uploadFile(file, onProgress) {
  const sasUri = await getUploadSasUri('pa+', file.name);
  await uploadFileToSasUri(sasUri, file, onProgress);
}

export async function uploadFiles(files, onProgress) {
  const promises = [];
  files.forEach((file) => {
    const p = uploadFile(file, onProgress);
    promises.push(p);
  });
  await Promise.all(promises);
}

export async function login(id, token) {
  try {
    await apiClient.post('/api/Auth/login', null, {
      params: {
        id: id,
        token: token,
      },
    });
  } catch (error) {
    throw error;
  }
}

export async function validateUser(token) {
  let isValid = false;
  try {
    await apiClient
      .get('/api/Token/ValidateUserAsync', {
        params: {
          token: token,
        },
      })
      .then((res) => {
        isValid = res.data;
      });
  } catch (error) {
    throw error;
  }
  return isValid;
}

export async function getPropertyAddress(guid) {
  let propertyAddress = "";
  try {
    await apiClient
      .get('/api/Token/GetPropertyAddressAsync', {
        params: {
          tokenGuidString: guid,
        },
      })
      .then((res) => {
        propertyAddress = res.data;
      });
  } catch (error) {
    throw error;
  }
  return propertyAddress;
}

export async function getContactInfo(token) {
  let contactInfo = null;
  try {
    await apiClient
      .get('/api/Support/GetContactInfo', {
        params: {
          token: token,
        },
      })
      .then((res) => {
        contactInfo = res.data;
      });
  } catch (error) {
    throw error;
  }
  return contactInfo;
}

export async function getValidFileTypes() {
  let validFileTypes = [];
  try {
    await apiClient
      .get('/api/Upload/GetValidFileTypes')
      .then((res) => {
        validFileTypes = res.data;
      });
  } catch (error) {
    throw error;
  }
  return validFileTypes;
}
