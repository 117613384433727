import { Button } from "@mui/material";

import { useProgress } from '../../context/ProgressContext';

const TryAgainButton = ({style}) => {
    const { setUploadPercent, setUploadStarted, setIsSuccess, setIsComplete } =
    useProgress();

    const tryAgain = () => {
        setUploadPercent(0);
        setIsComplete(false);
        setUploadStarted(false);
        setIsSuccess(false);
      };

    return (
        <Button
          variant="contained"
          style={{ textTransform: 'none', ...style }}
          onClick={tryAgain}
        >
          Let's Try Again
        </Button>
    )
}

export default TryAgainButton;