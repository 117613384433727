import { useProgress } from '../../context/ProgressContext';
import Send from '../send/index';
import AttachContainer from './AttachContainer';

export const Attach = () => {
  const { uploadStarted } = useProgress();
  return (
    <>{!uploadStarted ? <AttachContainer /> : <Send />}</>
  );
};
