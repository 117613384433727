import { Typography, Box } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHouseUser } from "@fortawesome/pro-regular-svg-icons";
import { useTheme } from "@mui/material";

const PropertyAddress = (props) => {
    const theme = useTheme();
    const { address } = props;
    return (
        <Box sx={{display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center'}}>
            <FontAwesomeIcon color={theme.palette.secondary.main} icon={faHouseUser} />
            <Typography sx={{p: 1}}>
                {address}
            </Typography>
        </Box>
    )
}

export default PropertyAddress;