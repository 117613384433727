import { Box } from "@mui/material"
import { SectionTitle } from "../../common/components/Form"
import { useCredentials } from "../../context/CredentialsContext";
import PropertyAddress from "../../common/components/PropertyAddress"

export const HomeHeader = () => {
    return (
        'Welcome'
    )
}

export const HomeTitle = () => {
    const { propertyAddress } = useCredentials();
    return (
        <Box>
            <SectionTitle>
                Upload Your Documents
            </SectionTitle>
            {propertyAddress &&
                <PropertyAddress address={propertyAddress}/>
            }
        </Box>
    )
}

export const HomeSubtitle = () => {
    return (
        'We need a few more items to get your loan closed. Attach and upload your documents using one of the options below.'
    )
}

export const HomeCardSubtitle = () => {
    return (
        'We need a few more items to get your loan closed. Attach and upload your documents using one of the options to the right.'
    )
}