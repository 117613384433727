import { v4 as uuidv4 } from 'uuid';

export function getHumanReadableFileSize(size) {
    if (!Number.isInteger(size)) {
        return 'NaN B';
    }
    if (size <= 0) {
        return '0 B';
    }

    const i = Math.floor(Math.log(size) / Math.log(1024));
    return `${(size / 1024 ** i).toFixed(2) * 1} ${['B', 'kB', 'MB', 'GB', 'TB', 'PB'][i]}`;
}

export function isFileSizeValid(file) {
    if (file === null || file.size === null) {
        return false;
    }
    let fileSizeLimit = 26214400;
    if (file.size > fileSizeLimit) {
        return false;
    }
    return true;
}

const getExtension = str => str.slice(str.lastIndexOf("."));

export function isValidExtension(validExtensions, file) {
    if (file === null || validExtensions === null) {
        return false;
    }
    let fileExt = getExtension(file.name);
    if (!validExtensions.includes(fileExt.toLowerCase())) {
        return false;
    }

    return true;
}

export function filterInvalidFiles(files) {
    if (files === null) {
        return [];
    }

    return files.filter(function (file) {
        if (isFileSizeValid(file)) {
            file.id = uuidv4();
            return file;
        }
    });
}

export function areAnyFilesInvalid(files) {
    if (files === null) {
        return true;
    }

    let originalLength = files.length;
    let filteredFiles = filterInvalidFiles(files);
    if (originalLength !== filteredFiles.length) {
        return false;
    }
    return true;
}
