import { Box } from '@mui/material';

export const Form = ({ children, ...props }) => {
  return (
    <Box sx={{ maxWidth: 320, m: 'auto' }}>
      <form {...props} noValidate>
        {children}
      </form>
    </Box>
  );
};

export const Container = ({ children, center }) => {
  if (center)
    return (
      <Box sx={{ maxWidth: 320, m: 'auto', textAlign: 'center' }}>
        {children}
      </Box>
    );
  return <Box sx={{ maxWidth: 320, m: 'auto' }}>{children}</Box>;
};
