import { Typography, Button, Box } from '@mui/material';
import { CameraAlt, CameraRoll, Folder } from '@mui/icons-material';
import { useRef } from 'react';
import { v4 as uuidv4 } from 'uuid';

import { useFiles } from '../../../context/FilesContext';
import { useFileError } from '../../../context/FileErrorContext';
import CameraInput from './CameraInput';
import GalleryInput from './GalleryInput';
import FileInput from './FileInput';
import { isFileSizeValid, isValidExtension } from '../../utils/filesUtil';

const AddDocuments = (props) => {
  const cameraInput = useRef();
  const galleryInput = useRef();
  const fileInput = useRef();

  const { setFiles, fileMimeTypes, imageMimeTypes, allExtensions } = useFiles();
  const { setShowMessage, setErrorMessage } = useFileError();

  const onChange = (addedFiles) => {
    let fileTooLarge = false;
    let invalidExtension = false;
    var filteredFiles = addedFiles.filter(function (file) {
      if (!isFileSizeValid(file)) {
        fileTooLarge = true;
        setErrorMessage('One or more files were larger than 25MB and could not be added.');
      } else if (!isValidExtension(allExtensions, file)) {
        invalidExtension = true;
        setErrorMessage('One or more files were blocked file types and could not be added.');
      } else {
        file.id = uuidv4();
        return file;
      }
    });
    setShowMessage(fileTooLarge || invalidExtension);
    setFiles((prevFiles) => [...prevFiles, ...filteredFiles]);
    resetInput();
  };

  function resetInput() {
    cameraInput.current.value = '';
    galleryInput.current.value = '';
    fileInput.current.value = '';
  }

  return (
    <>
      <Typography variant="caption" sx={{ textAlign: 'left' }}>ADD DOCUMENTS FROM</Typography>
      <Box>
        <CameraInput
          ref={cameraInput}
          onChange={onChange}
          accept={imageMimeTypes} />
        <Button
          startIcon={<CameraAlt />}
          variant="outlined"
          style={{ textTransform: 'none', margin: 5 }}
          onClick={() => cameraInput.current.click()}
        >
          Camera
        </Button>
        <GalleryInput
          ref={galleryInput}
          onChange={onChange}
          accept={imageMimeTypes}
        />
        <Button
          startIcon={<CameraRoll />}
          variant="outlined"
          style={{ textTransform: 'none', margin: 5 }}
          onClick={() => galleryInput.current.click()}
        >
          Roll
        </Button>
        <FileInput
          ref={fileInput}
          onChange={onChange}
          accept={fileMimeTypes}
        />
        <Button
          startIcon={<Folder />}
          variant="outlined"
          style={{ textTransform: 'none', margin: 5 }}
          onClick={() => fileInput.current.click()}
        >
          Folder
        </Button>
      </Box>
    </>
  );
};

export default AddDocuments;
