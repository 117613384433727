import { forwardRef } from 'react';

const GalleryInput = forwardRef((props, ref) => {
    const { onChange, accept } = props;
    return (
      <input
      ref={ref}
      type="file"
      accept={accept}
      hidden
      multiple
      onChange={(e) => {
        onChange([...e.target.files]);
      }}
    />
    )
});

export default GalleryInput;