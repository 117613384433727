import PropTypes from 'prop-types';
import { Typography, Button } from '@mui/material';
import { Cancel } from '@mui/icons-material';

export const RemoveFileButton = ({ fileName, onClick }) => {
    return (
        <Button endIcon={<Cancel/>} variant='outlined' size='small'
            style={{ display: 'flex', justifyContent: 'flex-start', width: '100%', textTransform: 'none' }}
            onClick={onClick}>
            <Typography noWrap style={{width: '100%', textAlign: 'left'}}>
                {fileName}
            </Typography>
        </Button>
    )
}

RemoveFileButton.propTypes = {
    fileName: PropTypes.string.isRequired,
    onClick: PropTypes.func
}