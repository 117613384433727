import { Box } from "@mui/material";
import { useEffect } from "react";

import StyledCard from "../styledCard/StyledCard";
import FileList from "../../common/components/FileList";
import CancelButton from "../../common/components/CancelButton";
import SendButton from "../../common/components/SendButton";
import { ReactComponent as InfoSvg } from '../../assets/img/infoImg.svg';
import { AttachSubtitle, AttachTitle, AttachHeader } from "./AttachText";
import { useProgress } from "../../context/ProgressContext";
import { useFiles } from "../../context/FilesContext";

const AttachCard = () => {
    const { setPageTitle } = useProgress();
    const { files, setFiles } = useFiles();

    useEffect(() => {
      setPageTitle(<AttachHeader />);
    },[])

    return (
        <StyledCard
            svg={<InfoSvg style={{ width: 'auto', height: '20%' }} />}
            title={<AttachTitle />}
            subtitle={<AttachSubtitle />}
            content={
                <Box sx={{ height: '50%', width: '70%', mt: 1, mb: 1, overflow: 'auto' }}>
                    <FileList files={files} setFiles={setFiles} />
                </Box>
            }
            actions={
                <Box>
                    <CancelButton />
                    <SendButton style={{ marginLeft: 50 }} />
                </Box>
            }
        >
        </StyledCard>
    )
}

export default AttachCard;