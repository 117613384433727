import { Button } from '@mui/material';
import { useState, useEffect } from 'react';

import { useProgress } from '../../context/ProgressContext';
import { useFiles } from '../../context/FilesContext';
import { uploadFiles } from '../../common/utils/docBoxClient';
import { validateUser } from '../../common/utils/docBoxClient';
import { useCredentials } from '../../context/CredentialsContext';

const SendButton = ({ style }) => {
    const { files } = useFiles();
    const { setUploadStarted, setUploadPercent, setIsSuccess, setIsComplete } =
        useProgress();
    const { token } = useCredentials();
    const [totalBytes, setTotalBytes] = useState(0);

    useEffect(() => {
        let total = 0;
        files.map((f) => {
            total += f.size;
        });
        setTotalBytes(total);
    }, [files]);

    const filesProgress = getInitialState();

    function getInitialState() {
        let initialFileProgress = [];
        files.forEach((file) => {
            initialFileProgress.push({ id: file.id, loadedBytes: 0 });
        });
        return initialFileProgress;
    }

    const onProgress = (file, ev) => {
        filesProgress.map((f) => {
            if (f.id === file.id) {
                f.loadedBytes = ev.loadedBytes;
            }
        });
        calculateProgress();
    };

    const calculateProgress = () => {
        let uploadedBytes = 0;
        filesProgress.forEach((f) => {
            uploadedBytes = uploadedBytes + f.loadedBytes;
        });
        let percent = (uploadedBytes / totalBytes) * 100;
        setUploadPercent(percent);
    };

    async function upload() {
        setUploadStarted(true);
        try {
            const batchSize = 5;
            for (let i = 0; i < files.length; i += batchSize) {
                let isValid = await validateUser(token);
                if (!isValid) {
                    throw Error;
                }
                const batch = files.slice(i, i + batchSize);
                await uploadFiles(batch, onProgress);
            }
            setIsSuccess(true);
        } catch {
            setIsSuccess(false);
        }
        setIsComplete(true);
    }

    return (
        <Button
            variant="contained"
            style={{ textTransform: 'none', ...style }}
            onClick={() => upload()}
            size="large"
        >
            Send
        </Button>
    )
}

export default SendButton;