import { useEffect } from 'react';

import { withSnackbar } from '../../common/utils/Snackbar.js';
import HomeContainer from './HomeContainer';
import { Attach } from '../attach/index';
import { useFiles } from '../../context/FilesContext';
import { useFileError } from '../../context/FileErrorContext';
import { useCredentials } from '../../context/CredentialsContext.js';
import { ContactContainer } from '../contact/ContactContainer.js';

const Home = (props) => {
  const { files } = useFiles();
  const { showContactUs } = useCredentials();
  const { showMessage, setShowMessage, errorMessage } = useFileError();

  useEffect(() => {
    if (showMessage) {
      props.snackbarShowMessage(errorMessage, 'error');
      setShowMessage(false);
    }
  },[showMessage]);

  return (
    <>
      {showContactUs ? <ContactContainer /> :
        <>
          {files.length === 0 && <HomeContainer />}
          {files.length > 0 && <Attach />}
        </>
      }
    </>
  );
};

export default withSnackbar(Home);
