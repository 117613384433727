import { Box, Button } from '@mui/material';
import { useRef } from 'react';
import { v4 as uuidv4 } from 'uuid';

import { useFiles } from '../../../context/FilesContext';
import { useFileError } from '../../../context/FileErrorContext';
import GalleryInput from './GalleryInput';
import CameraInput from './CameraInput';
import FileInput from './FileInput';
import { isFileSizeValid, isValidExtension } from '../../utils/filesUtil';

const AddDocumentsColumn = () => {
    const cameraInput = useRef();
    const galleryInput = useRef();
    const fileInput = useRef();
    const { setFiles, fileMimeTypes, imageMimeTypes, allExtensions } = useFiles();
    const { setShowMessage, setErrorMessage } = useFileError();

    const onChange = (addedFiles) => {
        let fileTooLarge = false;
        let invalidExtension = false;
        var filteredFiles = addedFiles.filter(function (file) {
            if (!isFileSizeValid(file)) {
                fileTooLarge = true;
                setErrorMessage('One or more files were larger than 25MB and could not be added.');
            } else if (!isValidExtension(allExtensions, file)) {
                invalidExtension = true;
                setErrorMessage('One or more files were blocked file types and could not be added.');
            } else {
                file.id = uuidv4();
                return file;
            }
        });
        setShowMessage(fileTooLarge || invalidExtension);
        setFiles((prevFiles) => [...prevFiles, ...filteredFiles]);
        resetInput();
    };

    function resetInput() {
        cameraInput.current.value = '';
        galleryInput.current.value = '';
        fileInput.current.value = '';
    }

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                width: '100%',
                height: '100%',
                alignItems: 'center',
                justifyContent: 'center',
            }}
        >
            <CameraInput
                ref={cameraInput}
                onChange={onChange}
                accept={imageMimeTypes} />
            <Button
                variant="contained"
                sx={{
                    width: '200px',
                    textTransform: 'none',
                }}
                size="large"
                onClick={() => cameraInput.current.click()}
            >
                Use Your Camera
            </Button>
            <GalleryInput
                ref={galleryInput}
                onChange={onChange}
                accept={imageMimeTypes}
            />
            <Button
                variant="contained"
                sx={{
                    width: '200px',
                    mt: 3,
                    textTransform: 'none',
                }}
                size="large"
                onClick={() => galleryInput.current.click()}
            >
                Select Photos
            </Button>
            <FileInput
                ref={fileInput}
                onChange={onChange}
                accept={fileMimeTypes}
            />
            <Button
                variant="contained"
                sx={{
                    width: '200px',
                    mt: 3,
                    textTransform: 'none',
                }}
                size="large"
                onClick={() => fileInput.current.click()}
            >
                Select Files
            </Button>
        </Box>
    )
}

export default AddDocumentsColumn;