import { List, ListItem } from '@mui/material';

import { RemoveFileButton } from './RemoveFileButton';

const FileList = (props) => {
    const { files, setFiles } = props;
    return (
        <List>
            {files.map((file) => (
                <ListItem sx={{ pl: 2, pr: 2 }} key={file.id}>
                    <RemoveFileButton
                        onClick={() => setFiles(files.filter((f) => f.id !== file.id))}
                        fileName={file.name}
                    />
                </ListItem>
            ))}
        </List>
    )
}

export default FileList;