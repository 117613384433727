import { Button, Box } from '@mui/material';
import { useEffect } from 'react';

import { useFiles } from '../../../context/FilesContext';
import { useProgress } from '../../../context/ProgressContext';
import ContactUs from '../../../common/components/ContactUs';
import MobileContainer from '../../../common/components/MobileContainer';
import { useCredentials } from '../../../context/CredentialsContext';
import { ReactComponent as EasySvg } from '../../../assets/img/easy.svg';
import { UploadSuccessTitle, UploadSuccessSubtitle, UploadSuccessHeader } from './UploadSuccessText';

export const UploadSuccessContainer = () => {
  const { setFiles } = useFiles();
  const { friendlyContactPhone, contactPhone, contactEmail } = useCredentials();
  const { setUploadPercent, setUploadStarted, setIsSuccess,
    setIsComplete, setPageTitle } = useProgress();

  useEffect(() => {
    setPageTitle(<UploadSuccessHeader />);
    getQualtricsSurvey();
    triggerQualtricsSuccess();
  }, []);

  function getQualtricsSurvey()
  {
    var g = function(e,h,f,g){ this.get=function(a){for(var a=a+"=",c=document.cookie.split(";"),b=0,e=c.length;b<e;b++){for(var d=c[b];" "==d.charAt(0);)d=d.substring(1,d.length);if(0==d.indexOf(a))return d.substring(a.length,d.length)}return null}; this.set=function(a,c){var b="",b=new Date;b.setTime(b.getTime()+6048E5);b="; expires="+b.toGMTString();document.cookie=a+"="+c+b+"; path=/; "}; this.check=function(){var a=this.get(f);if(a)a=a.split(":");else if(100!=e)"v"==h&&(e=Math.random()>=e/100?0:100),a=[h,e,0],this.set(f,a.join(":"));else return!0;var c=a[1];if(100==c)return!0;switch(a[0]){case "v":return!1;case "r":return c=a[2]%Math.floor(100/c),a[2]++,this.set(f,a.join(":")),!c}return!0}; this.go=function(){if(this.check()){var a=document.createElement("script");a.type="text/javascript";a.src=g;document.body&&document.body.appendChild(a)}}; this.start=function(){var t=this;"complete"!==document.readyState?window.addEventListener?window.addEventListener("load",function(){t.go()},!1):window.attachEvent&&window.attachEvent("onload",function(){t.go()}):t.go()};}; 
    try{(new g(100,"r","QSI_S_ZN_eqYD44dxeQABY7c","https://zneqyd44dxeqaby7c-uwm.siteintercept.qualtrics.com/SIE/?Q_ZID=ZN_eqYD44dxeQABY7c")).start()}catch(i){}
  }

  function triggerQualtricsSuccess() {
    window._qsie = window._qsie || [];
    window._qsie.push('UploadSuccess');
    window._qsie.push('UploadSuccess');
    showIntercept();
  }

  function showIntercept() {
    try {
      QSI.API.unload();
      QSI.API.load().done(QSI.API.run());
    }
    catch (error) {
      // Qualtrics not loaded
    }
  }

  const resetState = () => {
    setFiles([]);
    setIsComplete(false);
    setUploadPercent(0);
    setUploadStarted(false);
    setIsSuccess(false);
  }

  return (
    <MobileContainer
      svg={<EasySvg style={{ width: 'auto', height: '30%', maxHeight: 300 }} />}
      title={<UploadSuccessTitle />}
      subtitle={<UploadSuccessSubtitle />}
      content={
        <Box sx={{ display: 'flex', flexDirection: 'column', height: '50%', justifyContent: 'flex-end', alignItems: 'center' }}>
          <div id='ZN_eqYD44dxeQABY7c' />
          <Button
            variant="contained"
            sx={{ width: '250px', mb: 3, textTransform: 'none' }}
            onClick={resetState}
          >
            I Have More Documents
          </Button>
          <ContactUs friendlyPhoneNumber={friendlyContactPhone} phoneNumber={contactPhone} email={contactEmail} />
        </Box>
      }
    >
    </MobileContainer>
  );
};
