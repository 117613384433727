import { Box, CircularProgress } from '@mui/material';
import { useEffect } from 'react';

import { Container } from '../../../common/components/Form';
import VerifyingLinkDesc from './VerifyingLinkDesc';
import { useProgress } from '../../../context/ProgressContext';
import { VerifyingLinkHeader } from './VerifyingLinkText';

const VerifyingLinkContainer = () => {
  const { setPageTitle } = useProgress();

  useEffect(() => {
    setPageTitle(<VerifyingLinkHeader />);
  },[])

  return (
    <Box sx={{ pt: '10vh' }}>
      <VerifyingLinkDesc />
      <Container center='true'>
        <CircularProgress sx={{ mt: 5 }} />
      </Container>
    </Box>
  );
};

export default VerifyingLinkContainer;
