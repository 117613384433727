import { useState, createContext, useContext } from 'react';

const ProgressContext = createContext();

export function useProgress() {
  return useContext(ProgressContext);
}

export function ProgressProvider({ children }) {
  const [uploadPercent, setUploadPercent] = useState(0);
  const [uploadStarted, setUploadStarted] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isComplete, setIsComplete] = useState(false);
  const [pageTitle, setPageTitle] = useState('');

  return (
    <ProgressContext.Provider
      value={{
        uploadPercent,
        setUploadPercent,
        uploadStarted,
        setUploadStarted,
        isSuccess,
        setIsSuccess,
        isComplete,
        setIsComplete,
        pageTitle,
        setPageTitle
      }}
    >
      {children}
    </ProgressContext.Provider>
  );
}
