import { Box } from '@mui/material';
import { useEffect } from 'react';

import { useProgress } from '../../../context/ProgressContext';
import StyledCard from '../../styledCard/StyledCard';
import { ReactComponent as ProblemSvg } from '../../../assets/img/there was a problem.svg';
import ContactUs from '../../../common/components/ContactUs';
import { useCredentials } from '../../../context/CredentialsContext';
import TryAgainButton from '../../../common/components/TryAgainButton';
import { UploadFailedHeader, UploadFailedSubtitle, UploadFailedTitle } from './UploadFailedText';

const UploadFailedCard = () => {
    const { friendlyContactPhone, contactPhone, contactEmail } = useCredentials();
    const { setPageTitle } = useProgress();

    useEffect(() => {
      setPageTitle(<UploadFailedHeader />);
    },[])

    return (
        <StyledCard
            svg={<ProblemSvg style={{ width: 'auto', height: '30%' }} />}
            title={<UploadFailedTitle />}
            subtitle={<UploadFailedSubtitle />}
            content={
                <Box sx={{display: 'flex', flexDirection: 'column', height: '50%', justifyContent: 'flex-end', alignItems: 'center'}}>
                    <TryAgainButton style={{width: 200, marginTop: 20, marginBottom: 20}}/>
                    <ContactUs friendlyPhoneNumber={friendlyContactPhone} phoneNumber={contactPhone} email={contactEmail} />
                </Box>
            }
            >
        </StyledCard >
    )
}

export default UploadFailedCard;