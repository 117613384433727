import { ArrowBack } from "@mui/icons-material";
import { Button } from "@mui/material";
import { useCredentials } from "../../context/CredentialsContext";

const BackButton = () => {
    const { setShowContactUs } = useCredentials();
    return (
        <Button
            variant="outlined"
            color="primary"
            onClick={() => setShowContactUs(false)}
            size="large"
            startIcon={<ArrowBack/>}
            sx={{ width: 200, textTransform: 'none'}}
        >
            Back
        </Button>
    )
}

export default BackButton;