import { AppBar, IconButton, Toolbar, Box, Typography, Tooltip } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMoon, faSun } from '@fortawesome/free-solid-svg-icons';
import { Help } from '@mui/icons-material';

import { useTheme } from '../../../context/ThemeContext';
import UWM_logo from '../../../assets/img/UWM_logo.svg';
import { useCredentials } from '../../../context/CredentialsContext';
import { useProgress } from '../../../context/ProgressContext';

const Header = () => {
  const { theme, setTheme } = useTheme();
  const { verified, setShowContactUs } = useCredentials();
  const { pageTitle } = useProgress();
  return (
    <AppBar component="header">
      <Toolbar>
        <Box sx={{ display: 'flex', flexGrow: 1, width: '70%', alignItems: 'center' }} component="div">
          <img
            src={UWM_logo}
            alt="UWM logo"
            style={{ height: '24px', display: 'flex', marginRight: 16 }}
          />
          <Typography noWrap variant="h6">
            {pageTitle}
          </Typography>
        </Box>
        {verified &&
          <Box>
            <Tooltip title='Contact Us'>
              <IconButton onClick={() => setShowContactUs(true)} color="inherit">
                <Help />
              </IconButton>
            </Tooltip>
          </Box>
        }
        <Box sx={{ width: '40px', marginLeft: '10px' }}>
          {theme === 'light' ? (
            <Tooltip title='Dark Mode'>
              <IconButton color="inherit" onClick={() => setTheme('dark')}>
                <FontAwesomeIcon icon={faMoon} />
              </IconButton>
            </Tooltip>
          ) : (
            <Tooltip title='Light Mode'>
              <IconButton color="inherit" onClick={() => setTheme('light')}>
                <FontAwesomeIcon icon={faSun} />
              </IconButton>
            </Tooltip>
          )}
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default Header;
