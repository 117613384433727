import { Typography } from '@mui/material';

import { ReactComponent as BlueLockSvg } from '../../../assets/img/Blue Lock.svg';
import { SectionTitle, Container } from '../../../common/components/Form';

const VerifyingLinkDesc = () => {
  return (
    <Container center='true'>
      <BlueLockSvg style={{ maxWidth: '100%', paddingBottom: '3vh', height: 'auto' }} />
      <SectionTitle>
        Verifying Link.
      </SectionTitle>
      <Typography sx={{ pb: 2, textAlign: 'left' }}>
        Please wait while we verify access to this link
      </Typography>
    </Container>
  );
};

export default VerifyingLinkDesc;
