export const AttachHeader = () => {
    return (
        'Select Documents'
    )
}

export const AttachTitle = () => {
    return (
        'Ready To Send?'
    )
}

export const AttachSubtitle = () => {
    return (
        'Review your attached documents below. Add or remove as needed and send when ready!'
    )
}