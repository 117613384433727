export const UploadFailedHeader = () => {
    return (
        'Failed To Send'
    )
}

export const UploadFailedTitle = () => {
    return (
        'Oops, We Hit A Snag'
    )
}

export const UploadFailedSubtitle = () => {
    return (
        "Looks like something didn't process on our end. Click the button below to try again. Still not working? Contact us below."
    )
}