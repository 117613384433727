import { Box } from '@mui/material';

import AddDocumentsColumn from '../../common/components/Upload/AddDocumentsColumn';
import MobileContainer from '../../common/components/MobileContainer';
import { ReactComponent as CabinSvg } from '../../assets/img/cabin.svg';
import { HomeTitle, HomeSubtitle, HomeHeader } from './HomeText';
import { useProgress } from '../../context/ProgressContext';
import { useEffect } from 'react';

const Home = () => {
  const { setPageTitle } = useProgress();
  useEffect(() => {
    setPageTitle(<HomeHeader />);
  },[])

  return (
    <MobileContainer
      svg={<CabinSvg style={{ width: 'auto', height: '30%', maxHeight: 500 }} />}
      title={<HomeTitle/>}
      subtitle={<HomeSubtitle/>}
      content={
        <Box sx={{ display: 'flex', flexDirection: 'column', height: '50%', justifyContent: 'flex-end', alignItems: 'center' }}>
          <AddDocumentsColumn />
        </Box>
      }
    >
    </MobileContainer>
  );
};

export default Home;
