import { Box } from '@mui/material';
import { useEffect } from 'react';

import LinearProgressWithLabel from '../../common/components/LinearProgressWithLabel';
import StyledCard from "../styledCard/StyledCard";
import { ReactComponent as SendingSvg } from '../../assets/img/sending.svg';
import { useProgress } from '../../context/ProgressContext';
import { SendHeader } from './SendText';

const SendCard = () => {
    const { uploadPercent, setPageTitle } = useProgress();

    useEffect(() => {
      setPageTitle(<SendHeader />);
    },[])

    return (
        <StyledCard
            svg={<SendingSvg style={{ width: '60%', height: 'auto' }} />}
            title='Sending Documents'
            content={
                <Box sx={{ width: '70%' }}>
                    <LinearProgressWithLabel value={uploadPercent} />
                </Box>
            }>
        </StyledCard >
    )
}

export default SendCard;