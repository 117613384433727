import { UploadSuccessContainer } from '../upload/UploadSuccess/UploadSuccessContainer';
import { UploadFailedContainer } from '../upload/UploadFailed/UploadFailedContainer';
import { useProgress } from '../../context/ProgressContext';
import { useMobile } from '../../context/MobileContext';
import UploadFailedCard from './UploadFailed/UploadFailedCard';
import UploadSuccessCard from './UploadSuccess/UploadSuccessCard';

const Upload = () => {
  const { isSuccess } = useProgress();
  const { mobile } = useMobile();
  return (
    <>
      {isSuccess
        ?
        <>
          {mobile ? <UploadSuccessContainer /> : <UploadSuccessCard />}
        </>
        :
        <>
          {mobile ? <UploadFailedContainer /> : <UploadFailedCard />}
        </>
      }
    </>
  );
};

export default Upload;
