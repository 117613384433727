import { useParams } from 'react-router-dom';

import useVerification from '../../common/hooks/useVerification';
import VerifyingLinkContainer from './VerifyingLink/VerifyingLinkContainer';
import Home from '../../pages/home';
import VerificationFailedContainer from './VerificationFailed/VerificationFailedContainer';
import Combined from '../combined/Combined';
import { useMobile } from '../../context/MobileContext';

const VerifyingLink = () => {
  const { guid, token } = useParams();
  const { isLoading, success, errorTitle, errorMessage } = useVerification(guid, token);
  const { mobile } = useMobile();

  return (
    <>
      {!success &&
        <>
          {!isLoading && <VerificationFailedContainer errorTitle={errorTitle} errorMessage={errorMessage} />}
          {isLoading && <VerifyingLinkContainer />}
        </>
      }
      {success &&
        <>
          {!mobile && <Combined />}
          {mobile && <Home />}
        </>
      }
    </>
  );
};

export default VerifyingLink;
