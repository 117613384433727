import { Button } from "@mui/material";

import { useFiles } from "../../context/FilesContext";
import { useProgress } from '../../context/ProgressContext';

const CancelButton = ({ style }) => {
    const { setFiles } = useFiles();
    const { setUploadPercent, setUploadStarted, setIsSuccess, setIsComplete } =
      useProgress();

    function cancel() {
        setFiles([]);
        setUploadPercent(0);
        setIsComplete(false);
        setUploadStarted(false);
        setIsSuccess(false);
    };

    return (
        <Button
            variant="outlined"
            color="secondary"
            style={{ textTransform: 'none', ...style }}
            onClick={cancel}
            size="large"
        >
            Cancel
        </Button>
    )
}

export default CancelButton;