import { useState, createContext, useContext, useMemo } from 'react';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { uwmDarkTheme, uwmLightTheme } from 'lib-node-uwm-theme';

const ThemeContext = createContext();

export function useTheme() {
  return useContext(ThemeContext);
}

export function UwmThemeProvider({ children }) {
  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
  const [theme, setTheme] = useState(getInitialState());

  function getInitialState() {
    return prefersDarkMode ? 'dark' : 'light';
  }

  const themeObj = useMemo(
    () =>
      createTheme({
        ...(theme === 'light' ? uwmLightTheme : uwmDarkTheme),
      }),
    [theme],
  );

  return (
    <ThemeContext.Provider value={{ theme, setTheme }}>
      <ThemeProvider theme={themeObj}>{children}</ThemeProvider>
    </ThemeContext.Provider>
  );
}
