import { useEffect } from 'react';
import { Box } from '@mui/material';

import { useProgress } from "../../context/ProgressContext";
import { useFileError } from '../../context/FileErrorContext';
import CombinedContainer from "./CombinedContainer";
import { withSnackbar } from '../../common/utils/Snackbar';
import { useCredentials } from '../../context/CredentialsContext';
import { ContactContainer } from '../contact/ContactContainer';
import Send from '../send';

const Combined = (props) => {
    const { uploadStarted } = useProgress();
    const { showContactUs } = useCredentials();
    const { showMessage, setShowMessage, errorMessage } = useFileError();

    useEffect(() => {
        if (showMessage) {
          props.snackbarShowMessage(errorMessage, 'error');
          setShowMessage(false);
        }
      },[showMessage]);

    return (
        <>
            {showContactUs ? <ContactContainer /> :
                <Box sx={{ display: 'flex', height: '100%', width: '100%', justifyContent: 'center', p: 2, minHeight: 575 }}>
                    {uploadStarted
                        ?
                        <Send />
                        :
                        <CombinedContainer />
                    }
                </Box>
            }
        </>
    )
}

export default withSnackbar(Combined);