import { forwardRef } from 'react';

const CameraInput = forwardRef((props, ref) => {
    const { onChange, accept } = props;
    return (
        <input
          ref={ref}
          type="file"
          capture="camera"
          accept={accept}
          hidden
          multiple
          onChange={(e) => {
            onChange([...e.target.files]);
          }}
        />
    )
});

export default CameraInput;