import { BrowserRouter, Routes, Route } from 'react-router-dom';

import MainLayout from './common/layouts/MainLayout';
import VerifyingLink from './pages/verification';
import NotFound from './pages/NotFound';

const Router = () => (
  <BrowserRouter>
    <Routes>
      <Route path="*" element={<NotFound />} />
      <Route path="/:guid/:token" element={<MainLayout />}>
        <Route index element={<VerifyingLink />} />
      </Route>
    </Routes>
  </BrowserRouter>
);

export default Router;
