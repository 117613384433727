import { useEffect, useState } from 'react';

import { login, getContactInfo, getValidFileTypes, getPropertyAddress } from '../../common/utils/docBoxClient';
import { useCredentials } from '../../context/CredentialsContext';
import { useFiles } from '../../context/FilesContext';

const useVerification = (guid, token) => {
  const { setGuid, setToken, verified, setVerified,
    setContactEmail, setFeedbackUrl, setContactName,
    setContactPhone, setFriendlyContactPhone, setPropertyAddress } = useCredentials();
  const { setValidFileTypes } = useFiles();

  const [contactSuccess, setContactSuccess] = useState(false);
  const [fileTypesSuccess, setFileTypesSuccess] = useState(false);
  const [propertySuccess, setPropertySuccess] = useState(false);
  const [success, setSuccess] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [errorTitle, setErrorTitle] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);

  useEffect(() => {
    setGuid(guid);
    setToken(token);
    if (!verified) {
      setIsLoading(true);
      setErrorTitle(null);
      setErrorMessage(null);
      (async () => {
        try {
          await login(guid, token);
          setVerified(true);
        } catch (error) {
          try {
            var response = error.response.data;
            setErrorTitle(response.errorType);
            setErrorMessage(response.userMessage);
          }
          finally {
            setVerified(false);
            setIsLoading(false);
          }
        }
      })();
    }
  }, []);

  useEffect(() => {
    if (verified) {
      (async () => {
        try {
          let contactInfo = await getContactInfo(token);
          setContactEmail(contactInfo.email);
          setFeedbackUrl(contactInfo.feedbackURL);
          setContactName(contactInfo.name);
          setContactPhone(contactInfo.phone);
          setFriendlyContactPhone(contactInfo.friendlyPhone);
          setContactSuccess(true);
        } catch (error) {
          setIsLoading(false);
          setContactSuccess(false);
        }
      })();
    }
    else {
      setContactSuccess(false);
    }
  }, [verified]);

  useEffect(() => {
    if (verified) {
      (async () => {
        try {
          let response = await getValidFileTypes();
          setValidFileTypes(response.validFileTypes);
          setFileTypesSuccess(true);
        } catch (error) {
          setIsLoading(false);
          setFileTypesSuccess(false);
        }
      })();
    }
  }, [verified]);

  useEffect(() => {
    if (verified) {
      (async () => {
        try {
          let propertyAddress = await getPropertyAddress(guid);
          setPropertyAddress(propertyAddress);
          setPropertySuccess(true);
        } catch (error) {
          setIsLoading(false);
          setPropertySuccess(false);
        }
      })();
    }
  }, [verified]);

  useEffect(() => {
    if (verified && contactSuccess && 
      fileTypesSuccess && propertySuccess) {
      setSuccess(true);
      setIsLoading(false);
    }
  }, [verified, contactSuccess, fileTypesSuccess, propertySuccess])

  return { isLoading, success, errorTitle, errorMessage };
};

export default useVerification;
