import { Box } from '@mui/material';

import { useFiles } from '../../context/FilesContext';
import HomeCard from '../home/HomeCard';
import AttachCard from '../attach/AttachCard';
import DropDocumentsCard from '../dropDocuments/DropDocumentsCard';

const CombinedContainer = () => {
    const { files } = useFiles();
    return (
        <>
            <Box sx={{ display: 'flex', justifyContent: 'flex-end', width: '50%', pr: 1 }}>
                {files.length === 0 && <HomeCard />}
                {files.length > 0 && <AttachCard />}
            </Box>
            <Box sx={{ width: '50%', pl: 1 }}>
                <DropDocumentsCard />
            </Box>
        </>
    )
}

export default CombinedContainer;