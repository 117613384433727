import { Link, Typography } from '@mui/material';
import { useState, useEffect } from 'react';

import { ReactComponent as RedLockSvg } from '../../../assets/img/Red Lock.svg';
import { SectionTitle, Container } from '../../../common/components/Form';
import { useCredentials } from '../../../context/CredentialsContext';

const VerificationFailedDesc = (props) => {
    const { errorTitle, errorMessage } = props;
    const { friendlyContactPhone, contactPhone, contactEmail } = useCredentials();
    const [phoneNumberHref, setPhoneNumberHref] = useState('');
    const [emailHref, setEmailHref] = useState('');

    useEffect(() => {
        let phoneHref = 'tel:' + contactPhone;
        setPhoneNumberHref(phoneHref);
    }, [contactPhone])

    useEffect(() => {
        let emailHref = 'mailto:' + contactEmail;
        setEmailHref(emailHref);
    }, [contactEmail])

    function getPhoneLink() {
        return (
            <Link href={phoneNumberHref}>
                {friendlyContactPhone}
            </Link>
        )
    }

    function getEmailLink() {
        return (
            <Link href={emailHref}>
                {contactEmail}
            </Link>
        )
    }

    return (
        <Container center='true'>
            <RedLockSvg style={{ maxWidth: '100%', paddingBottom: '3vh', height: 'auto' }} />
            {errorTitle && errorMessage
                ?
                <>
                    <SectionTitle>
                        {errorTitle}
                    </SectionTitle>
                    <Typography sx={{ pb: 2, textAlign: 'left' }}>
                        {errorMessage} Please reach out to us at {getPhoneLink()} or {getEmailLink()} for assistance.
                    </Typography>
                </>
                :
                <>
                    <SectionTitle>
                        There Was A Problem
                    </SectionTitle>
                    <Typography sx={{ pb: 2, textAlign: 'left' }}>
                        There was a problem accessing this link. Please reach out to us at {getPhoneLink()} or {getEmailLink()} for assistance.
                    </Typography>
                </>
            }
        </Container>
    );
};

export default VerificationFailedDesc;
