import { Typography, Button } from "@mui/material";
import { useEffect, useState } from "react";

function ContactUs({ phoneNumber, friendlyPhoneNumber, email }) {
    const [phoneNumberHref, setPhoneNumberHref] = useState('');
    const [emailHref, setEmailHref] = useState('');

    useEffect(() => {
        let phoneHref = 'tel:' + phoneNumber;
        setPhoneNumberHref(phoneHref);
    }, [phoneNumber])

    useEffect(() => {
        let emailHref = 'mailto:' + email;
        setEmailHref(emailHref);
    }, [email])

    return (
        <>
            <Typography variant='h4'>Need Help?</Typography>
            {phoneNumber &&
                <Button href={phoneNumberHref} style={{ textTransform: 'none' }}>
                    {friendlyPhoneNumber}
                </Button>
            }
            {email &&
                <Button href={emailHref} style={{ textTransform: 'none' }}>
                    {email}
                </Button>
            }
        </>
    )
}

export default ContactUs;