import { useState, createContext, useContext } from 'react';

const CredentialsContext = createContext();

export function useCredentials() {
  return useContext(CredentialsContext);
}

export function CredentialsProvider({ children }) {
  const [guid, setGuid] = useState('');
  const [token, setToken] = useState('');
  const [verified, setVerified] = useState(false);

  const [contactEmail, setContactEmail] = useState('loancoordinator@uwm.com');
  const [feedbackUrl, setFeedbackUrl] = useState('https://www.uwm.com');
  const [contactName, setContactName] = useState('John Doe');
  const [contactPhone, setContactPhone] = useState('(866) 369-3627');
  const [friendlyContactPhone, setFriendlyContactPhone] = useState('(866) 3-MyDOCS');
  const [showContactUs, setShowContactUs] = useState(false);
  const [propertyAddress, setPropertyAddress] = useState('');

  return (
    <CredentialsContext.Provider
      value={{
        guid, setGuid, token, setToken, verified, setVerified,
        contactEmail, setContactEmail, feedbackUrl, setFeedbackUrl,
        contactName, setContactName, contactPhone, setContactPhone,
        friendlyContactPhone, setFriendlyContactPhone, showContactUs, 
        setShowContactUs, propertyAddress, setPropertyAddress
      }}
    >
      {children}
    </CredentialsContext.Provider>
  );
}
