import { Grid } from '@mui/material';

import { ReactComponent as QuestionSvg } from '../../assets/img/question.svg';
import ContactUs from '../../common/components/ContactUs';
import { useCredentials } from '../../context/CredentialsContext';
import BackButton from '../../common/components/BackButton';
import { useProgress } from '../../context/ProgressContext';
import { useEffect } from 'react';
import { ContactHeader } from './ContactText';

export const ContactContainer = () => {
  const { friendlyContactPhone, contactPhone, contactEmail } = useCredentials();
  const { setPageTitle } = useProgress();
  
  useEffect(() => {
    setPageTitle(<ContactHeader />);
  }, [])

  return (
    <Grid sx={{ display: 'flex', height: '100%', justifyContent: 'center', alignItems: 'center' }} container spacing={2}>
      <Grid sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }} item xs={12} md={3} lg={3} xl={3}>
        <QuestionSvg style={{ width: 'auto', height: 135, marginBottom: 10 }} />
        <ContactUs friendlyPhoneNumber={friendlyContactPhone} phoneNumber={contactPhone} email={contactEmail} />
      </Grid>

      <Grid sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} item xs={12}>
        <BackButton />
      </Grid>
    </Grid>
  );
};
