import { useState, createContext, useContext, useEffect } from 'react';

const FilesContext = createContext();

export function useFiles() {
  return useContext(FilesContext);
}

export function FilesProvider({ children }) {
  const [files, setFiles] = useState([]);
  const [validFileTypes, setValidFileTypes] = useState([]);
  const [allExtensions, setAllExtensions] = useState([]);
  const [imageMimeTypes, setImageMimeTypes] = useState([]);
  const [fileMimeTypes, setFileMimeTypes] = useState([]);
  const [allMimeTypes, setAllMimeTypes] = useState([]);
  const [totalBytes, setTotalBytes] = useState(0);

  useEffect(() => {
    let total = 0;
    files.map((f) => {
      total += f.size;
    });
    setTotalBytes(total);
  }, [files]);

  useEffect(() => {
    setImageMimeTypes([]);
    setFileMimeTypes([]);
    setAllMimeTypes([]);
    setAllExtensions([]);
    validFileTypes.map((fileType) => {
      if (fileType.mimeType.slice(0,6) === "image/") {
        setImageMimeTypes((prevTypes) => [...prevTypes, fileType.mimeType]);
      }
      else {
        setFileMimeTypes((prevTypes) => [...prevTypes, fileType.mimeType]);
      }
      setAllMimeTypes((prevTypes) => [...prevTypes, fileType.mimeType]);
      setAllExtensions((prevExtensions) => [...prevExtensions, fileType.extension]);
    });
  }, [validFileTypes]);

  return (
    <FilesContext.Provider value={{
      files, setFiles, totalBytes,
      validFileTypes, setValidFileTypes,
      imageMimeTypes, fileMimeTypes, allMimeTypes, allExtensions
    }}>
      {children}
    </FilesContext.Provider>
  );
}
