import { SendContainer } from './SendContainer';
import SendCard from './SendCard';
import Upload from '../upload/index';
import { useProgress } from '../../context/ProgressContext';
import { useMobile } from '../../context/MobileContext';

const Send = () => {
  const { isComplete } = useProgress();
  const { mobile } = useMobile();
  return (
    <>
      {isComplete
        ?
        <Upload />
        :
        <>
          {mobile ? <SendContainer /> : <SendCard />}
        </>
        }
    </>
  );
};

export default Send;
