import { Snackbar, Slide } from "@mui/material";
import Alert from '@mui/material/Alert';
import { useState } from "react";
import { useTheme } from '@mui/material';

export const withSnackbar = (WrappedComponent) => {
  return (props) => {
    const theme = useTheme();
    const [open, setOpen] = useState(false);
    const [message, setMessage] = useState("");
    const [duration, setDuration] = useState(3000);
    const [severity, setSeverity] = useState('info');
    const [borderColor, setBorderColor] = useState(theme.palette.info.main);

    function getBorderColorBySeverity(severity) {
      switch (severity) {
        case 'info':
          return theme.palette.info.main;
        case 'error':
          return theme.palette.error.main;
        case 'success':
          return theme.palette.success.main;
        case 'warning':
          return theme.palette.warning.main;
      }
    }

    const showSnackbar = (message, severity = 'info', duration = 3000) => {
      setMessage(message);
      setDuration(duration);
      setSeverity(severity);
      setBorderColor(getBorderColorBySeverity());
      setOpen(true);
    };

    const handleClose = (event, reason) => {
      if (reason === "clickaway") {
        return;
      }
      setOpen(false);
    };

    return (
      <>
        <WrappedComponent {...props} snackbarShowMessage={showSnackbar} />
        <Snackbar
          anchorOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          style={{ paddingTop: '70px' }}
          autoHideDuration={duration}
          open={open}
          onClose={handleClose}
          TransitionComponent={Slide}
        >
          <Alert severity={severity} sx={{ width: '100%', textAlign: 'left', border: '1px', borderStyle: 'solid', borderColor: { borderColor } }}>
            {message}
          </Alert>
        </Snackbar>
      </>
    );
  };
};
