import { Box } from '@mui/material';
import { useEffect } from 'react';

import LinearProgressWithLabel from '../../common/components/LinearProgressWithLabel';
import { ReactComponent as SendingSvg } from '../../assets/img/sending.svg';
import { useProgress } from '../../context/ProgressContext';
import MobileContainer from '../../common/components/MobileContainer';
import { SendHeader } from './SendText';

export const SendContainer = () => {
  const { uploadPercent, setPageTitle } = useProgress();

  useEffect(() => {
    setPageTitle(<SendHeader />);
  },[])

  return (
    <MobileContainer
      svg={<SendingSvg style={{ width: 'auto', height: '30%', maxHeight: 500 }} />}
      title='Sending Documents'
      content={
        <Box sx={{ width: '70%', pt: 1 }}>
          <LinearProgressWithLabel value={uploadPercent} />
        </Box>
      }
    >
    </MobileContainer>
  );
};
