import CssBaseline from '@mui/material/CssBaseline';

import { MobileProvider } from './context/MobileContext';
import { UwmThemeProvider } from './context/ThemeContext';
import { FilesProvider } from './context/FilesContext';
import { FileErrorProvider } from './context/FileErrorContext';
import { CredentialsProvider } from './context/CredentialsContext';
import { ProgressProvider } from './context/ProgressContext';
import Router from './Router';

const App = () => {
  return (
    <MobileProvider>
      <UwmThemeProvider>
        <CredentialsProvider>
          <FilesProvider>
            <FileErrorProvider>
              <ProgressProvider>
                <Router />
                <CssBaseline />
              </ProgressProvider>
            </FileErrorProvider>
          </FilesProvider>
        </CredentialsProvider>
      </UwmThemeProvider>
    </MobileProvider>
  );
};

export default App;
